@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&display=swap');
@font-face {
  font-family: 'JINS Next';
  src: url('assets/fonts/JINS_NextLight.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'JINS Next';
  src: url('assets/fonts/JINS_NextLight.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'JINS Next';
  src: url('assets/fonts/JINS_NextRegular.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
